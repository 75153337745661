export default function DownIt() {
  return (
    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector 832"
        d="M12 1L6.62222 7L0.999999 1"
        stroke="#1a325d"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}
