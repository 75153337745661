export default function Search1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M12.1498 10.0629H11.3819L11.1098 9.8159C12.0952 8.7401 12.6369 7.36667 12.6358 5.94626C12.6358 4.7702 12.2653 3.62055 11.571 2.6427C10.8768 1.66484 9.89009 0.902692 8.73565 0.452634C7.58121 0.0025764 6.31089 -0.115179 5.08534 0.114258C3.85979 0.343696 2.73404 0.910021 1.85047 1.74162C0.966898 2.57322 0.365177 3.63274 0.121399 4.7862C-0.122378 5.93966 0.00273745 7.13525 0.480924 8.22179C0.95911 9.30832 1.76889 10.237 2.80786 10.8904C3.84684 11.5438 5.06834 11.8925 6.3179 11.8925C7.88279 11.8925 9.32133 11.3528 10.4294 10.4563L10.6918 10.7124V11.4351L15.5517 16L17 14.6369L12.1498 10.0629ZM6.3179 10.0629C3.89766 10.0629 1.94397 8.22413 1.94397 5.94626C1.94397 3.66838 3.89766 1.82962 6.3179 1.82962C8.73814 1.82962 10.6918 3.66838 10.6918 5.94626C10.6918 8.22413 8.73814 10.0629 6.3179 10.0629Z"
        fill="#1A325D"
      />
    </svg>
  );
}
