"use client";

import { GAEvent, GA_EVENTS } from "@/helpers/gaEvents";
import styles from "./SideButtons.module.scss";
import cn from "clsx";
import { pushToDataLayer } from "@/helpers/gtag";
import CommonButton from "@/components/common/Analytics/CustomButton";
import { ModalTypes } from "@/types/modal";
import { Suspense } from "react";

const SideButtons = ({ data,isMobile=false }) => {
  const { STICKY_CTA_TEL, STICKY_CTA_WHATSAPP,CALL_CHAT_INTERACTION } = GAEvent;
  const res = data?.filter((x, i) => x?.enabled && i !== 0);
  
 
  const handleClick = (item:any) => {
    const itemUrl = item?.url || "#";
    if(itemUrl.includes('tel')){
      // for tel
      const gaData = GA_EVENTS.get(STICKY_CTA_TEL);
      pushToDataLayer(gaData);
      const gaData2 = {
        ...GA_EVENTS.get(CALL_CHAT_INTERACTION),
        cta_text: "call_us",
        section_name: "sticky_sidetray",
        page_type: "Home Page"
      };
      pushToDataLayer(gaData2);
      
    }else if(itemUrl.includes('whatsapp')){
      // for whatsapp
      const gaData = GA_EVENTS.get(STICKY_CTA_WHATSAPP);
      pushToDataLayer(gaData);

      const gaData2 = {
        ...GA_EVENTS.get(CALL_CHAT_INTERACTION),
        cta_text: "whatsapp_chat",
        section_name: "sticky_sidetray",
        page_type: "Home Page"
      };
      pushToDataLayer(gaData2);
    }
  }
  
const renderItemUrl=(itemUrl:string)=>{
  return itemUrl.includes('tel') && !isMobile ? "#":itemUrl;
}

  return (
    <div className={styles.sideBtnRoot}>
      <div className={`sideButtons flex flex-col ${styles.buttonPosition} ${styles.btnWrapper}`}>
        {res?.map((item, i) => (
          <div className={styles.imgSize} key={i}>
            {
              item.title === "call-button" && !isMobile ?
                  <CommonButton
                    GADataNew={
                      {
                        ...GA_EVENTS.get(CALL_CHAT_INTERACTION),
                        cta_text: "call_us",
                        section_name: "sticky_sidetray",
                        page_type: "Home Page"
                      }
                    }
                    isEventOnly={true}
                    type={"button"}
                    modalType={ModalTypes.CallUsModal}
                    bg="o"
                    color="b"
                    className={styles.stickCTAbtn}
                  >
                    <img
                      id="call-icon"
                      className={cn("aspect-square", styles.imgIconSize)}
                      src={item?.source?.data?.[0]?.attributes?.url}
                      alt={item?.alt || ''}
                      loading="lazy"
                    />
                  </CommonButton>
                :
                <a
                  href={renderItemUrl(item?.url) || '#'}
                  onClick={() => handleClick(item)}
                  target={item?.target?.[0] || "_self"}
                >
                  <img
                    id="wa-icon"
                    className={cn("aspect-square", styles.imgIconSize)}
                    src={item?.source?.data?.[0]?.attributes?.url}
                    alt={item?.alt || ''} 
                    loading="lazy"
                  />
                </a>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideButtons;