export default function Card({
  children,
  shadow = true,
  isBlue = false,
  noBottomRadius = false,
  isNotContainer = false,
  noXPadding = false,
  extraClasses = "",
}: {
  children: React.ReactNode;
  shadow?: boolean;
  isBlue?: boolean;
  noBottomRadius?: boolean;
  isNotContainer?: boolean;
  noXPadding?: boolean;
  extraClasses?: string;
}) {
  return (
    <div
      className={`Card 
      ${isBlue ? "bg-primary-blue text-white" : "bg-white"} 
      ${shadow ? "shadow-cardShadow" : ""} 
      ${noBottomRadius ? "rounded-t-2xl" : "rounded-2xl"} 
      ${
        noXPadding
          ? isNotContainer
            ? "lg:px-6 pt-10 pb-11"
            : "lg:px-20 lg:py-14"
          : ""
      } ${extraClasses}`}
    >
      {children}
    </div>
  );
}
